import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http: HttpClient) { }

  get(limit = -1,sort='[{"property":"name","direction":"ASC"}]'){
    return this._http.get(
      this.config.restAddress + '?limit=' + limit + '&sort=' + sort,
      this.httpPostOptions

    )
      .toPromise()
  }

  getById(id){
    let filter = '[{"property":"id","value":'+id+'}]';
    return this._http.get(
      this.config.restAddress + '?limit=' + 1 +'&filter=' + filter,
      this.httpPostOptions
    )
      .toPromise()
  }
}
