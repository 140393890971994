import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  //cache observable
  private cacheLanguageComponentFormsEditOrder$: Observable<any>;
  private cacheLanguageComponentGridsOrder$: Observable<any>;
  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) {}

  /**
   * Get languages editOrder
   */
  getLanguageCacheComponentFormsEditOrder(removeCache= false) {
    //if cached, get it
    if (!this.cacheLanguageComponentFormsEditOrder$ || removeCache === true) {
      this.cacheLanguageComponentFormsEditOrder$ = this.getLanguageComponentFormsEditOrderHelper().pipe(
        shareReplay(1)
      );
    }
    return this.cacheLanguageComponentFormsEditOrder$;
  }

  getLanguageComponentFormsEditOrderHelper() {
    return this._http.get(
      this.config.restLangComponentFormsEditOrder,
      this.httpPostOptions
    ).pipe(
      map(res=> res)
    )
  }


  /**
   * Get languages editOrder
   */
 async getLanguageCacheComponentGridsOrder() {
    return this._http.get(
      this.config.restLangComponentGridsOrder,
      this.httpPostOptions
    ).toPromise()
  }



}
