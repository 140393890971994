import {OrdersService} from "../../../../services/orders.service";
import {Component} from "@angular/core";
@Component({
  selector: 'app-save-colli',
  template: '',
})
export class SaveColli {

  order_id: number;

  constructor(
    private orderService:OrdersService
  ){

  }

  /**
   * Create a colli
   * @param colliData
   * @param callback
   */
  // create(colliData,callback) {
  //   let me = this;
  //
  //   // me.orderService.addColli(colliData,(res)=>{
  //   //   console.log('create colliData')
  //   //   console.log(res)
  //   //   if(typeof callback === 'function'){
  //   //     callback(res.data)
  //   //   }
  //   // })
  //
  //
  //   //me.orderService.addColli()
  //
  // }



}
