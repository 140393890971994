export class ProfileModel {
  profile_collis: any = {
    pallet_exchange: false,
    require_height: false,
    require_ldm: false,
    require_length: false,
    require_temp: false,
    require_vol: false,
    require_weight: false,
    require_width: false
  };
  allow_colli_h: boolean = false;
  allow_colli_l: boolean = false;
  allow_colli_ldm: boolean = false;
  allow_colli_unit_price: boolean = false;
  allow_colli_vol: boolean = false;
  allow_colli_w: boolean = false;
  allow_delivery_datetime: boolean = false;
  allow_order_drafts: boolean = false;
  allow_temp: boolean = false;
  enable_cold: boolean = false;
  enable_comments: boolean = false;
  enable_commodity_code: boolean = false;
  enable_freezing: boolean = false;
  enable_mrk: boolean = false;
  enable_reference: boolean = false;
  enable_warecode: boolean = false;
  show_extra_colli_field_1: boolean = false;
  show_extra_colli_field_2: boolean = false;
  show_extra_colli_field_3: boolean = false;
  show_extra_colli_field_4: boolean = false;
  show_extra_colli_field_5: boolean = false;
  show_extra_colli_field_6: boolean = false;
  show_extra_colli_field_7: boolean = false;
  show_extra_colli_field_8: boolean = false;
  show_extra_colli_field_9: boolean = false;
  show_extra_colli_field_10: boolean = false;
  show_fp_weight: boolean = false;
  show_label_count: boolean = false;
  show_ldm: boolean = false;
  show_m3: boolean = false;
  show_pickup_city: boolean = false;
  show_pll: boolean = false;
  show_statistics: boolean = false;
  show_temp: boolean = false;
  show_trailer_temp_type: boolean = false;
  show_weight: boolean = false;
  show_weight_in_ton: boolean = false;
  show_work_hours: boolean = false;
  vol_to_fp_weight_factor: boolean = false;
  weight_to_fp_weight_factor: boolean = false;
  ldm_to_fp_weight_factor: boolean = false;
}
