import {NgModule} from "@angular/core";
import {MatIconModule, MatNativeDateModule} from "@angular/material";
import {MatToolbarModule} from "@angular/material";
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material';



@NgModule({
    imports: [
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MatButtonModule,
        MatGridListModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule
    ],

    exports: [
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MatButtonModule,
        MatGridListModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatInputModule
    ]
})
export  class MaterialModule {}