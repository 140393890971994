import {DataService} from "../../../services/data.service";
export class OrderFilter {
  filter:any;
  selects:string;
  selectBase:string;
  dataService: DataService;

  constructor() {
    this.init();
  }

  /**
   * Default filter
   */
  init(){

    this.filter =[
      {"property": "main_order", "value": null},
      {"property": "is_approved", "value": true},
      {"property": "is_draft", "value": false},
      {"property": "is_template", "value": false},
      {"property": "o.order_state", "value": "1" }
    ]
    this.selectBase ='orderBase'
    this.selects = 'o.created_by,icu.name,o.fix_delivery,o.status,s.*,o.extra_field1,o.extra_field2,o.extra_field3,o.extra_field4,o.extra_field5,o.extra_field6,o.extra_field7,o.extra_field8,o.extra_field9,o.extra_field10,o.contract_number,icu.debitor_no, o.created_by';
  }

  /**
   * Set filter
   * @param property string
   * @param value string
   */
  set(property,value) {
    let obj = {'property': property, "value": value};
    this.filter.push(obj)
    return;
  }

  /**
   * get filter
   */
  get(){
    return this.filter;
  }

  /**
   * get selects
   */
  getSelects(){
    return this.selects;
  }

  /**
   * @desc Remove all filter
   * @param defaultFilter:boolean if true, set default filter from this.init()
   */
  clear(defaultFilter:boolean=true){
    this.filter = [];
    if(defaultFilter=== true){
      this.init();
    }

  }
}
