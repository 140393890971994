import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {Config} from "../../config/config";


@Component({
  selector: 'app-toolbar-main',
  templateUrl: './toolbar-main.component.html',
  styleUrls: ['./toolbar-main.component.css']
})
export class ToolbarMainComponent implements OnInit {

  constructor(private _auth: AuthService,private router: Router,private config:Config) { }
  ngOnInit() {}

  /**
   * Sign out
   */
  logoff(){
    let confirmLoggoff =  confirm('Click ok, to sign out');

    if(confirmLoggoff === true ){
      this._auth.logoff()
    }
  }



}
