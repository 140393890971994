import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";

import { CookieService, CookieOptions } from 'angular2-cookie/core';
import { GooglePlaceModule,GooglePlaceDirective } from "ngx-google-places-autocomplete";

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthComponent} from './components/auth/auth.component';
import {MaterialModule} from "./material.module";


import {EditOrderComponent} from "./components/forms/edit-order/edit-order.component";
import {ToolbarMainComponent} from "./toolbars/toolbar-main/toolbar-main.component";

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { NgSelect2Module } from 'ng-select2';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {Config} from "./config/config"
import {MomentModule} from "ngx-moment";
import {OrderFilter} from "./components/grids/orders-tab/order-filter";

import {OrdersTabComponent} from "./components/grids/orders-tab/orders-tab.component";
import {LoaderComponent} from "./components/loader/loader.component";
import {DataService} from "./services/data.service";
import { registerLocaleData } from '@angular/common';
import LocalDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import {SaveOrder} from "./components/forms/edit-order/tools/save-order";
import {SaveColli} from "./components/forms/edit-order/tools/save-colli";
registerLocaleData(LocalDa, 'da');
registerLocaleData(LocalDa, 'da-DK', localeDaExtra);


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    OrdersTabComponent,
    EditOrderComponent,
    ToolbarMainComponent,
    LoaderComponent,
    SaveOrder,
    SaveColli,
    Config
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MomentModule,
    NgSelect2Module,
    NgxMaterialTimepickerModule,
    GooglePlaceModule,
  ],
  providers: [
    Config,
    DataService,
    CookieService,
    GooglePlaceDirective,
    { provide: CookieOptions, useValue: {} },
    OrderFilter
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule {
}
