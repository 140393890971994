import {Config} from "../config/config";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class WaresService {
  //cache observable
  private cacheWares$: Observable<any>;
  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) {}

  /**
   * Get all Orders
   */
  getWares(removeCache= false) {
    //if cached, get it
    if (!this.cacheWares$ || removeCache === true) {
      this.cacheWares$ = this.getWaresHelper().pipe(
        shareReplay(1)
      );
    }
    return this.cacheWares$;
  }

  getWaresHelper(limit = -1) {
    return this._http.get(
      this.config.restWares + '?limit=' + limit,
      this.httpPostOptions
    ).pipe(
      map(res=> res)
    )
  }
  updateWare(form, wareId){

    if (wareId) {
      return this._http.put(
        this.config.restOrders +'/'+ wareId,
        form.value,
        this.httpPostOptions
      )
    }
  }

  getWareById(wareId){

    return this._http.get(
      this.config.restOrders + '/' + wareId,
      this.httpPostOptions
    ).toPromise();
  }

  getWaresPromise(){
    return this._http.get(this.config.restWares, this.httpPostOptions)
      .toPromise()
  }


}
