import {Component, OnDestroy, OnInit} from '@angular/core';
import {Config} from "./config/config"
import {AuthService} from "./services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})

export class AppComponent implements OnInit, OnDestroy {

  //main data
  public account = {};
  checkAuth: any;
  checkAuthId: any;
  loggedIn:boolean = false;
  checkSessionCountDown = 0;


  constructor(
    private config: Config,
    private auth: AuthService,
    private router: Router
  ) {
    //set account data
    this.setAccountData()
  }

  ngOnInit() {

    //check session
    let session = this.auth.checkAuth()
      .then((res)=>{
        //if logged in, redirect to orders view
        if(res['success']=== true){
          //reset countdown to logoff
          this.checkAuthId = setInterval(() => {
            //Check permisson, if false remove account data from local storage
            let session = this.auth.checkAuth()
              .then((resp) => {
                if(resp['success'] === false){
                  this.loggedIn = false;
                  localStorage.removeItem(this.config.localStorage['account']);
                  this.router.navigate(['/auth'])
                }
                this.loggedIn = true;
              })
              .catch((err)=>{
                console.error(err)
                if(err){
                  this.checkSessionCountDown =this.checkSessionCountDown +1;
                  if(this.checkSessionCountDown > 2){
                    this.router.navigate(['/auth'])
                  }
                } else {
                  this.checkSessionCountDown = 0;
                }
              })

          }, 10000);
          this.router.navigate(['/orders-tab'])
        }
      });



  }

  ngOnDestroy() {
    if (this.checkAuthId) {
      clearInterval(this.checkAuthId);
    }
  }

  /**
   * Set account data for global use
   */
  setAccountData() {
    if(this.loggedIn === true){
      let account = JSON.parse(localStorage.getItem(this.config.localStorage['account']));
      this.account = account.user.Account;
    } else {
      localStorage.removeItem(this.config.localStorage['account']);
    }

  }

}
