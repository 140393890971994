import {OrdersService} from "../../../../services/orders.service";
import {Component} from "@angular/core";
import {CollisModel} from "../../../../models/collis.model";
import {SaveColli} from "./save-colli";
import {LoaderComponent} from "../../../loader/loader.component";
import {ProfileModel} from "../../../../models/profile.model";
@Component({
  selector: 'app-save-order',
  template: './edit-order.component.html',
  providers: [
    SaveOrder
  ]
})
export class SaveOrder {

  order_id: number;

  constructor(
    private orderService:OrdersService
  ){

  }

  /**
   * Create an temporary order_id, uses if new order creates (has to delete, if no order creates )
   */
  createTempOrder(formData,callback) {
    let me = this;

    this.orderService.createTempOrder(formData, function (res) {
      //console.log('createTempOrder 1')
      me.order_id = res.data.id;
      if(typeof callback === 'function'){
        callback(me.order_id );
      }
    })

  }

  update(formEditOrder, orderId, orderState) {
    this.orderService.updateOrder(formEditOrder, orderId,orderState).subscribe(function (res) {
      //console.log(res)
    });
  }




}
