import {Injectable} from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";
import {shareReplay, map} from 'rxjs/operators';
import {Observable} from "rxjs";

/** Customer interface to save customers */
export interface Customers {
  customers: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  //cache observable
  private cache$: Observable<Array<Customers>>;
  //set header
  httpPostOptions = {
    withCredentials: true,
    params: {"limit": "-1"}
  };

  /**
   *
   * @param config get config
   * @param _http HTTP client
   */
  constructor(private config: Config, private _http: HttpClient) {
  }

  /**
   * Get Customer
   */
  getCustomers(removeCache = false) {
    //if cached, get it
    if (!this.cache$ || removeCache === true) {
      this.cache$ = this.getCustomersHelper().pipe(
        shareReplay(1)
      );
    }
    return this.cache$;
  }

  /**
   * get customer helper
   */
  private getCustomersHelper() {
    return this._http.get(
      this.config.restCustomers,
      this.httpPostOptions
    ).pipe(
      map(res => res['data']))
  }

  getCustomersPromise(){
    return this._http.get(this.config.restCustomers, this.httpPostOptions)
      .toPromise()
  }


  public getById(id, callback){
    if (id > 0) {
      //console.log(this.config.restOrderCollis + '/' + colliId)
      this._http.get(this.config.restCustomers + '/' + id, this.httpPostOptions)
        .toPromise().then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }
      })
    } else {
      if (typeof callback === 'function') {
        callback([{}]);
      }
    }
  }

}
