export class CollisModel {


  columns = [
    {
      headerName: '',
      field: 'checkbox',
      resizable: false,
      pinned: 'left',
      checkboxSelection: true,
      width: 100,
      headerCheckboxSelection: true
    },
    {headerName: 'id', field: 'id', hide: false},
    {headerName: 'order_id', field: 'order_id', hide: false},
    {headerName: 'mrk', field: 'mrk', sortable: true, editable: true},
    {headerName: 'ware', field: 'ware_code', sortable: true, editable: true},
    {headerName: 'description', field: 'description', sortable: true, editable: true},
    {
      headerName: 'count',
      field: 'count',
      sortable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 0
      },
      editable: true
    },
    {
      headerName: 'code', field: 'code', sortable: true,
      cellEditor: "selectBoxEditor",
      selectboxEditorParams: {
        values: []
      },
      valueFormatter: function (params) {
        //fix value text
        let output = '';
        //data from colDef
        let selectData = params.colDef.selectboxEditorParams.values;
        selectData.forEach(function (item) {
          //show text in grid cell from selected option text
          if (item.value == params.value) {
            output = item.text;
          }
        });
        return output;
      },
      editable: true,
    },
    {
      headerName: 'weight', field: 'weight', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 2
      }
    },
    {
      headerName: 'weight_in_ton', field: 'weight_in_ton', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 2
      }
    },
    {
      headerName: 'lngth', field: 'lngth', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 2
      }
    },
    {
      headerName: 'width', field: 'width', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 2
      }
    },
    {
      headerName: 'height', field: 'height', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 2
      }
    },
    {headerName: 'code_text', field: 'code_text', sortable: true, editable: true},
    {
      headerName: 'ldm', field: 'ldm', sortable: true, editable: true,
      cellEditor: "numericEditor",
      numericEditorParams: {
        round: 0
      }
    },
    {headerName: 'pll', field: 'pll', sortable: true, editable: true},
    {headerName: 'bund_pll', field: 'bund_pll', sortable: true, editable: true},
    {headerName: 'swap_pallets', field: 'swap_pallets', sortable: true, editable: true},
    {headerName: 'distance', field: 'distance', sortable: true, editable: true},
    {headerName: 'work_hours', field: 'work_hours', sortable: true, editable: true},
    {headerName: 'temp', field: 'temp', sortable: true, editable: true},
    {headerName: 'uc', field: 'uc', sortable: true, editable: true},
    {headerName: 'calc_price', field: 'calc_price', sortable: true, editable: true},
    {headerName: 'unit_price', field: 'unit_price', sortable: true, editable: true},
    {headerName: 'price_warning', field: 'price_warning', sortable: true, editable: true},
    {headerName: 'cold', field: 'cold', sortable: true, editable: true},
    {headerName: 'freezing', field: 'freezing', sortable: true, editable: true}

  ];

  default =  {
    sortable: true,
    resizable: true
  };

}
